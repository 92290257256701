.base-container {
    display: flex;
    flex-direction: column;
    min-height:90vh; 
    flex-wrap: wrap;
    overflow: hidden;
    flex-grow: 1;
}
  
.release-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    flex-grow: 1;
}

.hidden-release-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
}