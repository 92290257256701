.toggle-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: .5rem;
    height: 50px;
}

.active-device {
    background-color: #333;
}

.device-name {
    color: #ddd;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #555;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    top: 2px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #a28519;
}

input:focus+.slider {
    box-shadow: 0 0 1px #666667;
}

input:checked+.slider:before {
    transform: translateX(42px);
}

.slider.round {
    border-radius: 34px;
}

.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider.round:before {
    border-radius: 50%;
}

.active-device .slider {
    background-color: #2196F3;
}

.practice-mode-text {
    color: rgb(255, 255, 255);
    font-size: 0.75rem;
    margin-left: 10px;
    display: block;
}
