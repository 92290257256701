.App {
  margin: 0;
  padding: 0;
  height: 100vh;
}

/* Global scrollbar styling */
*::-webkit-scrollbar {
  width: 12px; /* Adjust width as needed for vertical scrollbar */
  height: 12px; /* Adjust height as needed for horizontal scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #4B5563; /* Scrollbar color */
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background-color: transparent; /* Scrollbar track color */
}

.login-container {
  background-color: rgb(24, 22, 22);
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.decision-button {
  background-color: #fcdd6d;
  color: black;
}
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
}

.popup-close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; 
}

.terms-text {
  font-size: 0.8rem;
  text-align: center;
  max-width: 300px; 
}

.navbar {
  position: relative; 
  display: flex; 
  flex-grow: 1; 
  width: 100%;
  flex-direction: column;
  gap: 1.25rem; 
  background-color: #1F2937;
  padding-left: 1.5rem; 
  padding-right: 2rem; 
  transition: all 0.5s; 
  overflow-y: auto; /* Enables scrolling */
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 0.25rem 1rem;
  height: 2.5rem;
  border-radius: 9999px;
  background-color: #f1f3f4;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-login-button:hover {
  background-color: #e0e0e0;
}

.google-login-button svg {
  color: #4285f4;
}

.google-login-button span {
  font-size: 0.875rem;
  font-weight: 500;
  color: #3c4043;
}

.simple-popup {
  position: fixed; /* Ensures the popup is placed relative to the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.signout-modal {
  position: fixed; 
  top: 50%;
  left: 50%;
  width: 25vw;
  height: 25vh;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
}

.signout-modal-content {
  position: relative; 
  width:100%;
  height: 80%;
  background-color: #fff;
  border-radius: 10px 10px 10px 10px;
  width: auto;
  max-width: 500px;
  z-index: 1051;
}

.priority-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Just below the popup */
}

.logo-container {
  position: absolute;
  z-index: 10; /* Higher than the other content in scrollbar */
  left: 104px;
  top: 15px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.button-container {
  position: absolute;
  bottom: 0;
  right:0;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.popup-title {
  font-size: 20px; 
  font-weight: bold; 
}

.login-container h1 {
  font-size: 38px;
  margin: 0;
  padding: 0;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Backdrop for non-google auth email/password entry popup */
.email-login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Content container for the email login */
.email-login-content {
  position: relative; 
  background-color: #fff;
  padding: 1.5rem;
  padding-bottom: 1rem;
  border-radius: 0.3rem;
  width: auto;
  max-width: 500px;
  z-index: 1051;
}

/* Style for all input fields within the .email-login-content */
.email-login-content input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  box-sizing: border-box; /* ensures padding doesn't add to the width */
}

/* Animations */
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.animate-slideUp {
  animation: slideUp 250ms ease-out forwards;
}

.animate-slideDown {
  animation: slideDown 250ms ease-in forwards;
}
.disable-pointer-events {
  pointer-events: none;
}