@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins',  sans-serif;
  font-weight: 400;
  font-style: Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Poppins',  sans-serif;
  font-weight: 400;
}

button:active {
  font-weight: 400;
  transition-duration: 200ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.markdown > * {
  all: revert; /* remove css preventing list bullets from rendering */
  text-align: left;
  /* Spacing */
  margin: 0 1.5rem;
  max-width: 800px;
}
/* Links in markdown */
.markdown a {
  color: #0366d6;
  text-decoration: none;
}
